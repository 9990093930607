import React from "react";
import { motion } from "framer-motion";

const Header = () => {
  // Function to handle scrolling to the contact form
  const scrollToCateringForm = () => {
    const cateringForm = document.getElementById("catering");
    if (cateringForm) {
      cateringForm.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      className="md:mt-0 mt-14"
      style={{
        backgroundImage: `url(https://ik.imagekit.io/jsklle928/Howe%20and%20Co/377104935_695096629148327_3942744214663337412_n%201.png?updatedAt=1696357098749)`,
        backgroundSize: "cover",
        height: "90vh",
        width: "100%",
      }}
    >
      <div className="flex flex-col items-center justify-center h-full px-4">
        <div className="flex flex-col items-center md:mt-4 ">
          <motion.h2
            initial={{ x: -200 }}
            animate={{ x: 0 }}
            transition={{ type: "spring" }}
            className="font-Black uppercase md:text-[75px] md:text-6xl text-5xl md:w-3/5 text-center md:leading-[4.7rem] md:mt-10 mt-10"
          >
            HOWE & CO <br />{" "}
            <span className="md:text-[65px] text-6xl">
              QUALITY FISH AND CHIPS
            </span>
          </motion.h2>
          <motion.h4
            initial={{ x: 200 }}
            animate={{ x: 0 }}
            transition={{ type: "spring" }}
            className="capitalize font-Black md:text-3xl text-2xl text-center"
          >
            we are franchise van 22 & van 55
          </motion.h4>
          <motion.h3
            initial={{ y: 200 }}
            animate={{ y: 0 }}
            transition={{ type: "spring" }}
            className="md:text-3xl text-2xl md:w-3/6 text-center mt-2 capitalize"
          >
            Our Mission Is To Bring The Joy Of Fresh Fish And Chips With
            Exceptional Customer Service To Every Community We Visit
          </motion.h3>
        </div>
        <motion.div
          initial={{ y: 200 }}
          animate={{ y: 0 }}
          transition={{ type: "bounce" }}
          className="grid md:grid-cols-3 gap-2 mt-8 "
        >
          <div>
            <a
              href="https://liveshare.ramtracking.com/?token=fda24a56-c44f-4871-9fc6-a0d99297d455"
              target="blank"
            >
              <button className="bg-customBlue text-lg uppercase md:px-8 px-6 md:py-3 py-2 rounded-lg text-tan font-medium w-full">
                track van 22
              </button>
            </a>
          </div>
          <div>
            <a
              href="https://liveshare.ramtracking.com/?token=ce090351-d2cc-4783-89d7-3365424e0ffc"
              target="blank"
            >
              <button className="bg-customBlue text-lg uppercase md:px-8 px-6 md:py-3 py-2 rounded-lg text-tan font-medium w-full">
                track van 55
              </button>
            </a>
          </div>
          <div>
            <button
              onClick={scrollToCateringForm}
              className="bg-customBlue text-lg uppercase md:px-8 px-6 md:py-3 py-2 rounded-lg text-tan font-medium"
            >
              catering inquiry
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Header;
